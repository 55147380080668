import landing1 from "../assets/landing-img-1.png";
import landing2 from "../assets/landing-img-2.png";
import landing3 from "../assets/landing-img-3.png";
import landing4 from "../assets/landing-img-4.png";

const featureList = [
    { featureTitle: "Kendi kişisel listenizi oluşturun", featureImage: landing1, featureImageAlt: "landing1" },
    { featureTitle: "Ürünleri öncelik sırasına koyun", featureImage: landing2, featureImageAlt: "landing2" },
    { featureTitle: "Temayı zevkinize göre özelleştirin", featureImage: landing3, featureImageAlt: "landing3" },
    { featureTitle: "Listenizi sevdiklerinizle kolayca paylaşın", featureImage: landing4, featureImageAlt: "landing4" },
];

export default featureList;