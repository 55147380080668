import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import logo from '../wishnee-logo-white.svg';

function Footer(props) {
  const menuTitle = {
    color: 'grey.200',
    fontSize: 16,
    fontWeight: 500,
    mb: 2,
  };
  const menuItem = {
    color: '#fff',
    fontSize: 27,
    fontWeight: 500,
    display: 'block',
  };

  return (
    <Grid container justifyContent="space-between" sx={{ bgcolor: 'primary.main', p: 8 }}>
      <Grid item xs={12} md={3}>
        <Typography sx={menuTitle}>İLETİŞİM</Typography>
        <Typography sx={menuItem}>info@wishnee.com</Typography>
      </Grid>
      <Grid item xs={12} md={3}>
        <Typography sx={menuTitle}>OFİSİMİZ</Typography>
        <Typography sx={menuItem}>Wishnee İzmir Ofis Erzene Mah. Ankara Cad. Ege Tekno Park No: 172 /67 Bornova/ İzmir</Typography>
      </Grid>
      <Grid item xs={12} md={3}>
        <Typography sx={menuTitle}>SOSYAL MEDYA</Typography>
        <Typography sx={menuItem} component={Link} to="/">
          Twitter
        </Typography>
        <Typography sx={menuItem} component={Link} to="/">
          Instagram
        </Typography>
        <Typography sx={menuItem} component={Link} to="/">
          Linkedin
        </Typography>
      </Grid>
      <Grid container>
        <Link to="/">
          <img src={logo} alt="logo" width="120px" />
        </Link>
      </Grid>
    </Grid>
  );
}

export default Footer;
