import React, { useState } from 'react';
import { Grid, Typography, Link as MuiLink, Button, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import CustomModal from '../components/CustomModal';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import DefaultLogo from '../assets/DefaultImage.png';
import SignUpModal from '../components/SignUpModal';

import IconButton from '@mui/material/IconButton';
import editIcon from '../assets/icons/edit-icon.svg';
import deleteIcon from '../assets/icons/delete-icon.svg';
import { useQuery } from '@apollo/client';
import { ALL_WISHLIST_QUERY, LOGGED_IN_USER_QUERY } from '../graphql';

function ItemCard({ productURL, productAlt, productTitle, productImage,productId, removeFunc }) {
  return (
    <>
      <Grid
        container
        sx={{
          bgcolor: '#F4F2F2',
          minHeight: '300px',
          position: 'relative',
          borderRadius: '4px',
          '&:hover': { '#productOverlay': { display: 'block' } },

          flex: 'auto',
        }}
      >
        <Box
          id="productOverlay"
          sx={{
            display: 'none',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.7)',
            zIndex: 2,
          }}
        >
          <Stack direction="column" alignItems="center" justifyContent="center" height="100%" paddingX="52px" color="#ffffff">
            <Box textAlign="center">
              <IconButton component="span" onClick={() => null}>
                <img src={editIcon} alt="Edit Icon" />
              </IconButton>
              <Typography variant="body1" marginTop="15px">
                Düzenle
              </Typography>
            </Box>
            <Divider
              sx={{
                backgroundColor: '#ffffff',
                width: '100%',
                marginY: '40px',
              }}
            />
            <Box textAlign="center">
              <IconButton component="span" onClick={removeFunc}>
                <img src={deleteIcon} alt="Edit Icon" />
              </IconButton>
              <Typography variant="body1" marginTop="15px">
                Sil
              </Typography>
            </Box>
          </Stack>
        </Box>
        <Box>
          <Grid
            item
            xs={12}
            sx={{
              position: 'relative',
              bgcolor: '#ffffff',
              margin: '16px 16px 0px 16px',
              minHeight: '330px',
              maxHeight: '330px',
              overflow: 'hidden',
            }}
          >
            <img src={productImage || DefaultLogo} alt={productAlt} style={{ height: '100%', objectFit: 'contain', width: '100%', position: 'absolute', objectPosition: 'center' }} />
          </Grid>
          <Typography
            variant="h2"
            fontSize="16px"
            margin="16px"
            sx={{
              overflow: 'hidden',
              display: '-webkit-box',
              '-webkit-line-clamp': '3',
              '-webkit-box-orient': 'vertical',
            }}
          >
            {productTitle}
          </Typography>
        </Box>
      </Grid>
      <Button variant="outlined" fullWidth sx={{ borderRadius: '4px', marginTop: 2, minHeight: '48px' }} onClick={() => window.open(productURL, '_blank')}>
        Ürüne Git
      </Button>
    </>
  );
}

function CreateList(props) {
  const [open, setOpen] = useState(false);
  const [signUpOpen, setSignUpOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [titleEditMode, setTitleEditMode] = useState(false);
  const [wishlistTitle, setWishlistTitle] = useState('Listem');
  const [wishlistDesc, setWishlistDesc] = useState('Liste açıklaması');
  const [descEditMode, setDescEditMode] = useState(false);

  const { data: user } = useQuery(LOGGED_IN_USER_QUERY);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { data, loading } = useQuery(ALL_WISHLIST_QUERY, {
    fetchPolicy: 'network-only',
  });
  if (loading) return null;

  // const wishlistItems = data?.allWishlists?.[0]?.wishlistItems || [];
  const handleCloseSignUp = () => {
    setSignUpOpen(false);
    //premium zart zurt kontrolü olması lazımdı
    //should show "share your list" modal - o modal daha yok..
  };

  const addItemHandle = item => {
    console.log('add item ', item);
    setOpen(false);
    setItems([...items, item]);
  };

  const removeItem = (id) =>{
    console.log('id: ', id);
    setItems(items.filter((item)=>(item.image !== id)))
    };
  const addItemCard = (
    <Grid container justifyContent="center" alignItems="center" disableRipple component={Button} height="330px" sx={{ bgcolor: '#F4F2F2', minHeight: '300px' }} onClick={handleOpen}>
      <AddIcon sx={{ width: 24, height: 24 }} />
      Ürün ekle
    </Grid>
  );

  const seePricingCard = (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        disableRipple
        component={Button}
        height="330px"
        onClick={() => null}
        sx={{
          bgcolor: '#F4F2F2',
          minHeight: '300px',
          color: 'rgba(40, 38, 51, 1)',
          borderRadius: '6px',
          border: '2px solid rgba(40, 38, 51, 1)',
        }}
      >
        Daha fazla ürün eklemek için premium üyelik alın
      </Grid>
      <Button variant="contained" fullWidth sx={{ borderRadius: '4px', marginTop: 2, minHeight: '48px' }} onClick={() => null}>
        <Typography variant="body1">Ücretlendirmeyi Gör</Typography>
      </Button>
    </>
  );

  const saveWishlist = list => {
    console.log('items: ', items);
    const { loggedInUser } = user;
    if (loggedInUser) {
      //check existing list, membership plan type
    } else {
      //prompt the user with you must need to sign up first popup
      setSignUpOpen(true);
    }
    // createWishlist({
    //   variables: {
    //     name: 'tets',
    //   },
    // });
  };

  const editIcon = (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.2872 6.68889L13.3093 7.71111L3.24399 17.7778H2.22191V16.7556L12.2872 6.68889ZM16.2866 0C16.0089 0 15.72 0.111111 15.509 0.322222L13.4759 2.35556L17.642 6.52222L19.675 4.48889C20.1083 4.05556 20.1083 3.35556 19.675 2.92222L17.0754 0.322222C16.8532 0.1 16.5755 0 16.2866 0ZM12.2872 3.54444L0 15.8333V20H4.16609L16.4533 7.71111L12.2872 3.54444Z"
        fill="#B0AFB2"
      />
    </svg>
  );

  return (
    <>
      <Grid container className="page-padding-vertical">
        <Grid item xs={12}>
          <Typography variant="h1">Liste Oluştur</Typography>
          <Typography sx={{ color: 'text.secondary', mt: 4 }}>
            Ücretsiz planda oluşturacağınız listeye maksimum 10 ürün ekleyebilirsiniz. <br /> Eğer hesabınız varsa giriş yaparak devam edin veya{' '}
            <MuiLink component={Link} color="accent.main" to="/">
              premium
            </MuiLink>{' '}
            üye olun.
          </Typography>
        </Grid>
        <Grid container item spacing={4} xs={12} sx={{ mt: 4 }}>
          <Grid item xs={4}>
            <Box marginBottom={4}>
              {!titleEditMode ? (
                <Stack direction="row" alignItems="center">
                  <Typography
                    variant="h4"
                    marginRight={1}
                    onClick={() => {
                      setTitleEditMode(true);
                    }}
                  >
                    {wishlistTitle}
                  </Typography>
                  <IconButton
                    aria-label="Edit Wishlist Title"
                    onClick={() => {
                      setTitleEditMode(true);
                    }}
                  >
                    {editIcon}
                  </IconButton>
                </Stack>
              ) : (
                <TextField
                  InputProps={{
                    classes: {
                      input: { fontSize: 27, fontWeight: 500 },
                    },
                    style: {
                      fontSize: 27,
                      fontWeight: 500,
                    },
                  }}
                  fullWidth
                  autoFocus
                  variant="standard"
                  value={wishlistTitle}
                  onChange={event => setWishlistTitle(event.target.value)}
                  onBlur={event => setTitleEditMode(wishlistTitle ? false : true)}
                />
              )}
            </Box>
            {!descEditMode ? (
              <Stack direction="row" alignItems="center">
                <Typography
                  sx={{ color: 'text.secondary' }}
                  maxWidth="70%"
                  marginRight={1}
                  onClick={() => {
                    setDescEditMode(true);
                  }}
                >
                  {wishlistDesc}
                </Typography>
                <IconButton
                  aria-label="Edit Wishlist Title"
                  onClick={() => {
                    setDescEditMode(true);
                  }}
                >
                  {editIcon}
                </IconButton>
              </Stack>
            ) : (
              <TextField
                InputProps={{
                  classes: {
                    input: { fontSize: 27, fontWeight: 500 },
                  },
                }}
                fullWidth
                autoFocus
                variant="standard"
                value={wishlistDesc}
                onChange={event => setWishlistDesc(event.target.value)}
                onBlur={event => setDescEditMode(wishlistDesc ? false : true)}
              />
            )}
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h4">Gizlilik</Typography>
            <RadioGroup aria-labelledby="list-privacy-selection" defaultValue="public" name="list-privacy">
              <FormControlLabel value="public" control={<Radio />} sx={{ color: 'text.secondary' }} label="Herkese açık" />
              <FormControlLabel value="private" control={<Radio />} sx={{ color: 'text.secondary' }} label="Bana özel" />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid container item xs={12} sx={{ mt: 8 }} spacing={4} rowSpacing={6}>
          {items.map((item, index) => (
            <Grid item xs={3} key={index} sx={{ display: 'flex', flexDirection: 'column' }}>
              {console.log('item',item)}
              <ItemCard productTitle={item.name} productImage={item.image} productAlt={item.name} productURL={item.url} productId={item.id} removeFunc={()=> removeItem(item.image)}/>
            </Grid>
          ))}

          {items.length < 5 ? (
            <Grid item xs={3}>
              {addItemCard}
            </Grid>
          ) : (
            <Grid item xs={3}>
              {seePricingCard}
            </Grid>
          )}
        </Grid>
        <Grid container justifyContent="center" sx={{ mt: 8, mb: 4 }}>
          <Button color="primary" variant="contained" size="large" onClick={saveWishlist}>
            Listeyi kaydet
          </Button>
        </Grid>
      </Grid>
      <SignUpModal open={signUpOpen} handleClose={handleCloseSignUp} />
      <CustomModal open={open} handleClose={handleClose} addItemHandle={addItemHandle} />
    </>
  );
}

export default CreateList;
