import React from "react";
import { Grid, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";

function Hero(props) {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: "90vh" }}
    >
      <Grid
        container
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
      >
        <Grid item xs={10}>
          <Typography
            variant="h1"
            align="center"
            style={{ lineHeight: "58px" }}
          >
            Sevdiğiniz ürünleri internetin herhangi bir yerinden kendi istek
            listenize kolayca ekleyin.
          </Typography>
        </Grid>
        <Grid item style={{ marginTop: 64 }}>
          <Button
            color="primary"
            variant="contained"
            component={Link}
            to="/create-list"
            size="large"
          >
            Liste oluştur
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Hero;
