import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { DialogTitle, Grid, IconButton } from '@mui/material';
import CustomInput from './CustomInput';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { ALL_WISHLIST_QUERY, CREATE_WISH_LIST_ITEM_MUTATION, CREATE_WISH_LIST_MUTATION, LINK_PREVIEW_MUTATION } from '../graphql';

const BootstrapDialogTitle = props => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'rgba(40, 38, 51, 1)',
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomModal({ open, handleClose, addItemHandle }) {
  const [productValue, setProductValue] = React.useState('');

  const { data, loading, refetch } = useQuery(ALL_WISHLIST_QUERY);

  const [linkPrev] = useMutation(LINK_PREVIEW_MUTATION);
  const [createWishlist] = useMutation(CREATE_WISH_LIST_MUTATION);
  const [createWishlistItem] = useMutation(CREATE_WISH_LIST_ITEM_MUTATION);

  if (loading) return null;

  const whishlistHandle = async () => {
    // let wishlistId = data?.allWishlists?.[0]?.id;
    // console.log('wishlistId: ', wishlistId);

    // if (!wishlistId) {
    //   const { data } = await createWishlist();
    //   console.log('data: ', data);
    //   wishlistId = data.createWishlist?.id;
    // }

    // const { data: linkdata } = await linkPrev({
    //   variables: {
    //     url: productValue,
    //   },
    // });

    // await createWishlistItem({
    //   variables: {
    //     wishlistId,
    //     name: linkdata?.linkPreview.title,
    //     url: productValue,
    //     image: linkdata?.linkPreview.image,
    //   },
    // }).then(() => {
    //   refetch();
    // });

    linkPrev({
      variables: {
        url: productValue,
      },
    }).then(data => {
      console.log('data: ', data);
      addItemHandle({ name: data?.data?.linkPreview.title, url: productValue, image: data?.data?.linkPreview.image });
    });
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="customized-dialog-title" aria-describedby="customized-dialog-description" maxWidth="md" fullWidth>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} />
      <DialogContent style={{ display: 'flex', justifyContent: 'center' }}>
        <Grid
          container
          style={{
            maxWidth: 436,
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <Grid item xs={12} style={{ marginTop: 64, marginBottom: 30 }}>
            <Typography variant="h2" fontSize="27px">
              Ürünün linkini ekleyin
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="title">Sen linki aşağıdaki kutucuğa yapıştırdığında, biz senin için ürünün bilgilerini getireceğiz.</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
        <Grid
          container
          style={{
            maxWidth: 436,
            textAlign: 'center',
          }}
        >
          <Grid item xs={12}>
            <CustomInput label="Ürün linki" placeholder="https://www.example.com/product" fullWidth value={productValue} onChange={e => setProductValue(e.target.value)} />
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 64 }}>
            <Button variant="contained" color="primary" fullWidth style={{ height: 53, borderRadius: 4, marginTop: 16 }} onClick={whishlistHandle}>
              Ürünü Getir
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
