import * as React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CustomInput from '../components/CustomInput';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function ForgotPassword() {
  const navigate = useNavigate();
  const handleSubmit = event => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
    });
    navigate('/forgot-password-success');
  };

  return (
    <Grid container alignItems="center" justifyContent="center" className="full-height-page">
      <Grid item xs={12} sm={7} md={5} textAlign="center">
        <Grid item sm={12}>
          <Typography variant="h3" color="Text">
            Şifremi unuttum
          </Typography>
          <Grid item sm={12} paddingTop="30px">
            <Typography variant="span" color={'subtitle'}>
              Hesabınızla ilişkili email adresini girin, şifrenizi sıfırlamanız için size bir email gönderelim.
            </Typography>
          </Grid>
        </Grid>
        <Grid item sm={12} component="form" noValidate onSubmit={handleSubmit} paddingTop="30px">
          <Stack spacing={2}>
            <CustomInput required fullWidth id="email" placeholder="E-posta" name="email" autoComplete="email" />
            <Button type="submit" fullWidth variant="contained" style={{ height: '46px', borderRadius: '4px' }}>
              Yenileme Linki Gönder
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ForgotPassword;
