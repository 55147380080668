import React from "react";
import { useQuery } from "@apollo/client";
import { LOGGED_IN_USER_QUERY } from "./graphql";
import Container from '@mui/material/Container';
import Route from "./config/Route"
import CustomAppBar from './components/CustomAppBar';
import Footer from './components/Footer';

export default function App(props) {
  const { data } = useQuery(LOGGED_IN_USER_QUERY);
  console.log(data);

  return (
    <>
      <CustomAppBar />
      <Container style={{ paddingTop: '96px' }}>
        <Route />
      </Container>
      <Footer />
    </>
  );
}
