import { useMutation, useQuery } from '@apollo/client';
import { Grid, Stack, Link as MuiLink } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CustomInput from '../components/CustomInput';
import { LOGGED_IN_USER_QUERY, SIGNUP_USER_MUTATION } from '../graphql';

function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

function validatePassword(password) {
    var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return re.test(password);
}

function SignUp() {
    const [signupUser] = useMutation(SIGNUP_USER_MUTATION);
    const { refetch } = useQuery(LOGGED_IN_USER_QUERY);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const handleSubmit = async event => {
        setIsSubmitting(true);
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        await signupUser({
            variables: {
                email: data.get('email'),
                firstName: data.get('firstName'),
                lastName: data.get('lastName'),
                password: data.get('password'),
            },
        }).then(({ data, errors }) => {
            if (data?.signupUser?.token) {
                localStorage.setItem('auth-token', data?.signupUser?.token);
                refetch();
                navigate('/');
            } else {
                enqueueSnackbar(errors?.[0]?.message, { variant: 'error' });
            }
        });
        setIsSubmitting(false);
    };

    const [emailStatus, setEmailStatus] = useState(true);
    const [passwordStatus, setPasswordStatus] = useState(true);
    const emailValidateText = "Lütfen geçerli bir e-posta adresi giriniz";
    const passwordValidateText = "Şifreniz en az 8 karakterden oluşmalı, Büyük harf, küçük harf, rakam ve özel karakter içermeli. örn: ! @ # ? ] *";

    return (
        <Grid container alignItems="center" justifyContent="center" className="full-height-page" >
            <Grid item xs={12} sm={7} md={5} textAlign="center">
                <Grid item sm={12}>
                    <Typography variant="h3" color="Text">
                        Kayıt Ol
                    </Typography>
                    <Grid item sm={12} paddingTop="30px">
                        <Typography variant="span" color={'subtitle'}>
                            Zaten bir wishnee hesabın var mı?{' '}
                        </Typography>

                        <MuiLink component={Link} color="accent.main" to="/sign-in">
                            Giriş yap
                        </MuiLink>
                    </Grid>
                </Grid>
                <Grid item sm={12} component="form" noValidate onSubmit={handleSubmit} paddingTop="30px">
                    <Stack spacing={2}>
                        <CustomInput required fullWidth id="email" placeholder="Email" name="email" autoComplete="email" onChange={(e) => setEmailStatus(validateEmail(e.target.value))} helperText={!emailStatus ? emailValidateText : ""} error={!emailStatus} />
                        <Stack direction={'row'} spacing={2}>
                            <CustomInput required fullWidth id="firstName" placeholder="Ad" name="firstName" autoComplete="firstName" />
                            <CustomInput required fullWidth id="lastName" placeholder="Soyad" name="lastName" autoComplete="lastName" />
                        </Stack>
                        <CustomInput required fullWidth id="password" placeholder="Şifre" name="password" autoComplete="password" type="password" onChange={(e) => setPasswordStatus(validatePassword(e.target.value))} helperText={!passwordStatus ? passwordValidateText : ""} error={!passwordStatus} />
                        <Button type="submit" fullWidth variant="contained" style={{ height: '46px', borderRadius: '4px' }} disabled={isSubmitting}>
                            Kayıt Ol
                        </Button>
                        <Typography color="#B0AFB2" fontSize={14}>
                            Kayıt ol butonuna tıkladığında
                            <Typography variant="span" marginX={0.5} fontWeight={700} sx={{ textDecoration: 'underline' }}>
                                Kullanıcı Sözleşmesini
                            </Typography>
                            ve
                            <Typography variant="span" marginX={0.5} fontWeight={700} sx={{ textDecoration: 'underline' }}>
                                Gizlilik Antlaşmasını
                            </Typography>
                            kabul etmiş sayılırsınız.
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default SignUp;
