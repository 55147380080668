import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import logo from '../wishnee-logo.svg';
import { Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import { useApolloClient, useQuery } from '@apollo/client';
import { LOGGED_IN_USER_QUERY } from '../graphql';

const styles = {
  appbar: {
    backgroundColor: 'background.main',
    boxShadow: 'none',
    padding: '16px 0',
  },
};

function stringAvatar(name) {
  return {
    sx: { width: 32, height: 32, fontSize: 18 },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

function DefaultHeader() {
  return (
    <Stack spacing={2} direction="row">
      <Button color="secondary" component={Link} to="/sign-in">
        Giriş yap
      </Button>

      <Button color="secondary" component={Link} to="/sign-up">
        Kayıt ol
      </Button>
      <Button color="primary" variant="outlined" component={Link} to="/create-list" sx={{ borderRadius: 1 }}>
        Liste oluştur
      </Button>
    </Stack>
  )
}

function LoggedInHeader({ userFullName }) {
  const client = useApolloClient()
  return (
    <Stack spacing={1} direction="row" alignItems="center">
      <Avatar {...stringAvatar(userFullName)} />
      <Typography color="text.primary">{userFullName.replace(/\s/g, '').toLowerCase()}</Typography>
      <Button color="secondary" onClick={() => {
        localStorage.removeItem("auth-token")
        client.resetStore();
      }}>
        Çıkış yap
      </Button>
    </Stack>
  )
}
export default function CustomAppBar({ children }) {
  // const [isAuth, setIsAuth] = useState(true);
  const { data } = useQuery(LOGGED_IN_USER_QUERY)

  const isAuth = data?.loggedInUser;
  const userFullName = data?.loggedInUser?.firstName + " " + data?.loggedInUser?.lastName;

  return (
    <AppBar sx={styles.appbar}>
      <Toolbar>
        <Box flexGrow={1}>
          <Link to="/">
            <img src={logo} alt="logo" width="120px" />
          </Link>
        </Box>
        {
          isAuth ? <LoggedInHeader userFullName={userFullName} /> : <DefaultHeader />
        }
      </Toolbar >
    </AppBar >
  );
}
