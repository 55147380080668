import Hero from "../components/Hero";
import FeatureLayout from "../components/Feature/FeatureLayout";
import Preview from "../components/Preview";
import ContactHero from "../components/ContactHero";
import featureList from "../utils/featureList";

function Landing() {
    return (
        <>
            <Hero />
            <FeatureLayout featureList={featureList} />
            <Preview />
            <ContactHero />
        </>
    );
}

export default Landing;