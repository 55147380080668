import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { DialogTitle, Grid, IconButton } from '@mui/material';
import CustomInput from './CustomInput';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import LoadingSpinner from './LoadingSpinner';
const BootstrapDialogTitle = props => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'rgba(40, 38, 51, 1)',
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};


BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function SignUpModal({ open, handleClose }) {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = () => {
    setIsSubmitting(true);
    //check if user exist
    //if exist, log in
    //if first time create user and log in

    setTimeout(()=>{
        setIsSubmitting(false);
        handleClose();
    },500)

  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="customized-dialog-title" aria-describedby="customized-dialog-description" maxWidth="md" fullWidth>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} />
      <DialogContent style={{ display: 'flex', justifyContent: 'center' }}>
        <Grid
          container
          style={{
            maxWidth: 436,
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <Grid item xs={12} style={{ marginTop: 64, marginBottom: 30 }}>
            <Typography variant="h2" fontSize="27px">
              Bir adım daha kaldı
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="title">Listeni kaydetmek için adına ve e-posta adresine ihtiyacımız var.</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
        <Grid
          container
          spacing={1}
          style={{
            maxWidth: 436,
            textAlign: 'center',
          }}
        >
          <Grid item xs={6} sx={{ mb: 1 }}>
            <CustomInput label="firstName" placeholder="Ad" autoComplete="name" fullWidth />
          </Grid>
          <Grid item xs={6} sx={{ mb: 1 }}>
            <CustomInput label="lastName" placeholder="Soyad" autoComplete="name" fullWidth />
          </Grid>
          <Grid item xs={12}>
            <CustomInput  label="E-posta" placeholder="E-posta" autoComplete="email" fullWidth />
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 64, marginTop: 16 }}>
            <Button disabled={isSubmitting} variant="contained" color="primary"  onClick={handleSubmit} fullWidth style={{ padding: 16, borderRadius: 4, marginTop: 16 }}>
              {isSubmitting ? <LoadingSpinner /> : 'Kaydet'}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
