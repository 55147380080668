import * as React from "react";
import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import TextField from '@mui/material/TextField';

const StyledCustomInput = styled(TextField)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    fontSize: 12,
  },
  "& .MuiTextField-input": {
    fontFamily: "'Space Grotesk', sans-serif",
    fontSize: "16px",
    fontWeight: 500,
    borderRadius: 4,
    color: "#282633",
    position: "relative",
    backgroundColor: "#ffffff",
    border: "1px solid #F2F2F2",
    padding: "14px 23px",
    "&:focus": {
      boxShadow: "none",
      border: "1px solid #e9e9e9",
      backgroundColor: "#ffffff",
    },
  },
}));

export default function CustomInput({
  required,
  fullWidth,
  id,
  placeholder,
  name,
  autoComplete,
  type,
  onChange,
  value,
  helperText,
  error
}) {

  return (
    <FormControl variant="standard" fullWidth>
      <StyledCustomInput
        required={required}
        fullWidth={fullWidth}
        id={id}
        placeholder={placeholder}
        name={name}
        autoComplete={autoComplete}
        type={type}
        onChange={onChange}
        value={value}
        helperText={helperText}
        error={error}
      />
    </FormControl>
  );
}
