import { createTheme } from '@mui/material/styles';
const font = "'Space Grotesk', sans-serif";
const primaryColor = '#3F0D21';
const accentColor = '#D32868';
const mainTextColor = '#282633';
const secondaryTextColor = '#616161';
const mainBgColor = '#FCFCFC';

const Theme = createTheme({
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 8,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 2,
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 1.2,
          borderWidth: 1,
          padding: '8px 16px',
          textTransform: 'initial',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        sizeLarge: {
          // Some CSS
          padding: '16px 48px',
          fontSize: 18,
        },
        outlinedPrimary: {
          backgroundColor: '#ffffff',
          borderColor: primaryColor,
          '&:hover': {
            border: `1px solid ${primaryColor}`,
            backgroundColor: primaryColor,
            color: '#fff',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: mainBgColor,
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          position: "absolute",
          width: "100%",
          left: 0,
          top: "50%",
          transform: "translate(100%, -50%)"
        }
      }
    },
  },
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: mainTextColor,
    },
    accent: {
      main: accentColor,
    },
    grey: {
      100: '#F7F8F9',
      150: '#CECECE',
      200: '#B0AFB2',
      600: '#727374',
    },
    neutral: {
      main: mainTextColor,
    },
    background: {
      main: mainBgColor,
      lightGrey: '#F7F8F9',
    },
    text: {
      primary: mainTextColor,
      secondary: secondaryTextColor,
    }
  },
  header: {
    gap: '96px',
  },
  typography: {
    fontFamily: font,
    lineHeght: 1.2,
    h1: {
      fontSize: 42,
      fontWeight: 700,
    },
    h2: {
      fontWeight: 700,
    },
    h3: {
      fontWeight: 700,
    },
    h4: {
      fontSize: 27,
      fontWeight: 500,
    },
    h6: {
      fontSize: 24,
      fontWeight: 500,
    },
    body1: {
      fontSize: 16,
      fontWeight: 500,
    },
    body2: {
      fontSize: 16,
    },
    link: {
      fontSize: 16,
      fontWeight: 500,
      color: accentColor,
    },
  },
});

export default Theme;
