import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function FeatureItem({ featureTitle, featureImage, featureImageAlt }) {
  return (
    <Grid item xs={12} sm={6}>
      <Stack
        spacing={5}
        justifyContent="center"
        alignItems="center"
        maxWidth="298px"
        minHeight="272px"
        marginX="auto"
        textAlign="center"
      >
        <Box minHeight="96px" display="flex" alignItems="center">
          <img src={featureImage} alt={featureImageAlt} />
        </Box>
        <Typography sx={{ fontSize: 27, fontWeight: 500 }}>
          {featureTitle}
        </Typography>
      </Stack>
    </Grid>
  );
}

export default FeatureItem;
