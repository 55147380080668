import React from 'react';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { Grid, Typography, Button, FormControl } from '@mui/material';
import { CONTACT_MUTATION } from '../graphql';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';

const StyledCustomInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
    fontSize: 12,
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    color: '#282633',
    backgroundColor: '#FFFFFF',
    border: '1px solid #D5D3D3',
    padding: '12px 24px',
    '&:focus': {
      boxShadow: 'none',
    },
  },
}));

function ContactHero(props) {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [createSendinBlueContact] = useMutation(CONTACT_MUTATION);
  const { enqueueSnackbar } = useSnackbar();
  const handleSubmit = async (event) => {
    setIsSubmitting(true);
    event.preventDefault();
    const data = new FormData(event.target);
    await createSendinBlueContact({ variables: { email: data.get("email") } }).then(({ data, errors }) => {
      if (data?.createSendinBlueContact) {
        enqueueSnackbar('Listeye katıldınız!', {
          variant: 'success'
        });
        event.target.reset()
      } else {
        enqueueSnackbar('Bir hata oluştu', {
          variant: 'error'
        });
      }
    })
    setIsSubmitting(false);
  };
  return (
    <Grid container sx={{ pt: 14, pb: 14 }}>
      <Grid container justifyContent="center" alignItems="center" sx={{ p: 8, bgcolor: 'background.lightGrey' }}>
        <Grid item xs={10}><Typography variant="h1" component="p" align="center" sx={{ fontWeight: 700 }}>
          Bir sorunuz mu var? E-postanızı yazın, sizinle iletişime geçelim.
        </Typography></Grid>
        <Grid
          item
          component="form"
          noValidate
          onSubmit={handleSubmit}
          container justifyContent="center" alignItems="center" spacing={2} sx={{ mt: 6 }}
        >
          <Grid item xs={6}>
            <FormControl variant="standard" fullWidth>
              <StyledCustomInput
                required
                fullWidth
                id="email"
                placeholder="E-posta"
                name="email"
                autoComplete="email" />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl variant="standard" fullWidth>
              <Button type="submit" color="primary" variant="contained" sx={{ p: '12px 36px' }} disabled={isSubmitting} >
                Gönder
              </Button>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ContactHero;
