import { gql } from "@apollo/client";

export const LOGGED_IN_USER_QUERY = gql`
  query loggedInUser {
    loggedInUser {
      id
      firstName
      lastName
      email
      subscriptionEndsAt
    }
  }
`;

export const ALL_WISHLIST_QUERY = gql`
  query AllWishlists {
    allWishlists {
      id
      name
      wishlistItems {
        id
        name
        image
        url
      }
    }
  }
`;

export const SIGNUP_USER_MUTATION = gql`
  mutation signupUser(
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
  ) {
    signupUser(
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
    ) {
      token
    }
  }
`;
export const CREATE_WISH_LIST_MUTATION = gql`
  mutation CreateWishlist {
    createWishlist {
      id
      name
    }
  }
`;
export const CREATE_WISH_LIST_ITEM_MUTATION = gql`
  mutation CreateWishlistItem(
    $wishlistId: ID!
    $name: String
    $image: String
    $url: String
  ) {
    createWishlistItem(
      wishlistId: $wishlistId
      name: $name
      image: $image
      url: $url
    ) {
      id
      image
      name
      url
    }
  }
`;

export const AUTHENTICATE_USER_MUTATION = gql`
  mutation authenticateUser($email: String, $password: String) {
    authenticateUser(email: $email, password: $password) {
      token
    }
  }
`;

export const CONTACT_MUTATION = gql`
  mutation createSendinBlueContact($email: String) {
    createSendinBlueContact(email: $email)
  }
`;

export const LINK_PREVIEW_MUTATION = gql`
  mutation linkPreview($url: String!) {
    linkPreview(url: $url) {
      id
      title
      image
    }
  }
`;
