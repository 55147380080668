import * as React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link as MuiLink } from '@mui/material';
import CustomInput from '../components/CustomInput';
import { Stack } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { useMutation, useQuery } from '@apollo/client';
import { AUTHENTICATE_USER_MUTATION, LOGGED_IN_USER_QUERY } from '../graphql';
import { useSnackbar } from 'notistack';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 48,
  height: 24,
  padding: 0,
  borderRadius: 21,
  display: 'flex',
  alignItems: 'center',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 24,
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(12px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(24px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(213, 211, 211, 1)' : 'rgba(211, 40, 104, 1)',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 18,
    height: 18,
    borderRadius: '50%',
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 20 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(213, 211, 211, 1)',
    boxSizing: 'border-box',
  },
}));

export default function SignIn() {
  const [authenticateUser] = useMutation(AUTHENTICATE_USER_MUTATION);
  const { refetch } = useQuery(LOGGED_IN_USER_QUERY);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const handleSubmit = event => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    authenticateUser({
      variables: {
        email: data.get('email'),
        password: data.get('password'),
      },
    }).then(({ data, errors }) => {
      if (data?.authenticateUser?.token) {
        localStorage.setItem('auth-token', data?.authenticateUser?.token);
        refetch();
        navigate('/');
      } else {
        enqueueSnackbar(errors?.[0]?.message, { variant: 'error' });
      }
    });
  };
  return (
    <Grid container alignItems="center" justifyContent="center" className="full-height-page">
      <Grid item xs={12} sm={7} md={5} textAlign="center">
        <Grid item sm={12}>
          <Typography variant="h3" color="Text">
            Giriş Yap
          </Typography>
          <Grid item sm={12} paddingTop="30px">
            <Typography variant="span" color={'subtitle'}>
              Henüz bir wishnee hesabın yok mu?{' '}
            </Typography>
            <MuiLink component={Link} color="accent.main" to="/sign-up">
              Kayıt Ol
            </MuiLink>
          </Grid>
        </Grid>
        <Grid item sm={12} component="form" noValidate onSubmit={handleSubmit} paddingTop="30px">
          <Stack spacing={2}>
            <CustomInput required fullWidth id="email" placeholder="E-posta" name="email" autoComplete="email" />
            <CustomInput required fullWidth id="password" placeholder="Şifre" name="password" autoComplete="password" type="password" />
            <Grid item xs={12} style={{ display: 'flex', marginTop: 28, marginBottom: 12 }}>
              <Grid
                item
                xs={6}
                style={{
                  justifyContent: 'flex-start',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <AntSwitch inputProps={{ 'aria-label': 'ant design' }} />
                <Typography variant="body1" style={{ marginLeft: 11, color: 'rgba(97, 97, 97, 1)' }}>
                  Beni Hatırla
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Link to="/forgot-password">
                  <Typography
                    variant="span"
                    color="primary"
                    style={{
                      textDecoration: 'underline',
                      justifyContent: 'flex-end',
                      display: 'flex',
                    }}
                  >
                    Şifremi Unuttum
                  </Typography>
                </Link>
              </Grid>
            </Grid>
            <Button type="submit" fullWidth variant="contained" style={{ height: '46px', borderRadius: '4px' }}>
              Giriş Yap
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
}
