import Grid from "@mui/material/Grid";
import preview from "../assets/preview.png";

function Preview() {
    return (
        <Grid container sx={{ pt: 6, pb: 8 }}>
            <Grid
                container
                justifyContent="center"

            >
                <Grid item xs={11}>
                    <img src={preview} alt="liste masaüstü görünümü" style={{ width: '100%' }} />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Preview