import { useRoutes } from "react-router-dom";
// Import Route Components
import Landing from '../pages/Landing';
import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import CreateList from '../pages/CreateList';
import ForgotPassword from "../pages/ForgotPassword";
import ForgotPasswordSuccess from "../pages/ForgotPasswordSuccess";

const ROUTES = [
    { path: "/", element: <Landing /> },
    { path: "/sign-in", element: <SignIn /> },
    { path: "/sign-up", element: <SignUp /> },
    { path: "/create-list", element: <CreateList /> },
    { path: "/forgot-password", element: <ForgotPassword /> },
    { path: "/forgot-password-success", element: <ForgotPasswordSuccess /> },
];

const Route = () => {
    // useRoutes() hook to define and render routes using regular JavaScript objects instead of <Routes> and <Route> elements.
    let routes =  useRoutes(ROUTES);
    return routes;
};

export default Route;