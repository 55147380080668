import React from "react";
import reportWebVitals from './reportWebVitals';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { ApolloProvider } from '@apollo/client/react';
import { client } from './apollo-client';
import { SnackbarProvider } from 'notistack';
import lightTheme from './styles/theme/theme';
import CssBaseline from "@mui/material/CssBaseline";
import './styles/globals.css';
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <SnackbarProvider>
      <ApolloProvider client={client}>
        <ThemeProvider theme={lightTheme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </ApolloProvider>
    </SnackbarProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
