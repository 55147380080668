import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

function ForgotPasswordSuccess() {
  return (
    <Grid
      container
      sx={{
        height: "100vh",
        backgroundColor: "#e5e5e50d",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Grid
        item
        sm={12}
        paddingTop="125px"
        maxWidth={782}
        style={{
          display: "flex",
          textAlign: "center",
          maxWidth: "782px",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h3"
          color="Text"
          paddingTop="70px"
          style={{ fontSize: 43 }}
        >
          Size şifre değiştirme linki gönderdik, lütfen e-posta hesabınızı
          kontrol edin
        </Typography>
      </Grid>
    </Grid>
  );
}

export default ForgotPasswordSuccess;
