
import { ApolloClient } from '@apollo/client';
import { ApolloLink, HttpLink, split } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';

// apollo new local state (reactive variables)
const cache = new InMemoryCache();

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('auth-token');
    // return the headers to the context so httpLink can read them

    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    };
});

const wsLink = new WebSocketLink({
    options: {
        connectionParams: () => {
            return {
                Authorization: `Bearer ${localStorage.getItem('auth-token')}`,
            };
        },
        lazy: true,
        reconnect: true,
    },
    uri: process.env.REACT_APP_WSS_URL,
});

// put query name in URL
const uriFunction = ({ operationName }) => {
    return `${process.env.REACT_APP_API_URL}/${operationName}`;
};

// console.log GraphQL errors
const httpLink = ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
            // eslint-disable-next-line no-console
            console.error(graphQLErrors);
        }
        if (networkError) {
            //logoutUser();
            // eslint-disable-next-line no-console
            // localStorage.removeItem(process.env.REACT_APP_AUTH_TOKEN);
            // localStorage.removeItem('auth-state');
            // window.location = '/login';
        }
    }),
    new HttpLink({
        uri: uriFunction,
    }),
]);

// concat authLink and httpLink
const splitAuthLink = ApolloLink.from([authLink, httpLink]);

// send subs to different URL.
const link = split(
    // split based on operation type
    ({ query }) => {
        const { kind, operation } = getMainDefinition(query);
        return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    splitAuthLink,
);

export const client = new ApolloClient({
    cache,
    defaultOptions: {
        mutate: { errorPolicy: 'all' },
        query: { errorPolicy: 'all' },
    },
    link,
});