import  Grid  from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import React, { Component } from 'react';


class LoadingSpinner extends Component {
  state = {};

  render() {
      const { color } = this.props;
    return (
      <Grid container style={{ flex: 1 }} justifyContent="center" alignItems="center">
        <CircularProgress style={{ color: color ? color : '#fff',width: 24, height: 24 }} />
      </Grid>
    );
  }
}

export default LoadingSpinner;
