import Grid from "@mui/material/Grid";
import FeatureItem from "./FeatureItem";

function FeatureLayout({ featureList }) {
  return (
    <Grid
      container
      xs={12}
      spacing={2}
      justifyContent="space-between"
      sx={{ pt: 0, pb: 12 }}
      style={{ display: "flex", justifyContent: "center" }}
    >
      {featureList.map((element, index) => (
        <FeatureItem {...element} key={index} />
      ))}
    </Grid>
  );
}

export default FeatureLayout;
